<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Pengguna'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari pengguna"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-none d-lg-block text-none"
          @click="$router.push('/input-user')"
        >
          Tambah Pengguna
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-lg-none d-md-block text-none"
          @click="$router.push('/input-user')"
        >
          Tambah
        </v-btn>
      </div>

      <div class="d-flex pb-4">
        <v-autocomplete
          v-model="monthlyLenderId"
          :items="lenders"
          item-text="nama_pengguna"
          item-value="id_lender_db"
          label="Pilih Lender"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>

        <span class="px-1" />

        <v-menu
          ref="monthMenu"
          v-model="monthMenu"
          :close-on-content-click="false"
          :return-value.sync="monthYearData"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="monthYearData"
              color="primary"
              outlined
              dense
              label="Bulan dan Tahun"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="monthYearData"
            type="month"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="monthMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.monthMenu.save(monthYearData)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <span class="px-2" />

        <v-btn
          color="primary"
          rounded
          class="white--text d-none d-lg-block text-none"
          @click="toMonthly()"
        >
          Get Monthly
        </v-btn>
        <v-btn
          color="primary"
          rounded
          class="white--text d-lg-none d-md-block text-none"
          @click="toMonthly()"
        >
          Monthly
        </v-btn>
      </div>

      <v-tabs v-model="tab" background-color="transparent" color="#D65646" grow>
        <v-tab class="text-none">
          Lender
        </v-tab>
        <v-tab class="text-none">
          Borrower
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              class="cursor-pointer"
              :headers="headers"
              :items="lenders"
              :items-per-page="10"
              :search="searchQuery"
              @click:row="toDetail"
            ></v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-data-table
              class="cursor-pointer"
              :headers="headers"
              :items="borrowers"
              :items-per-page="10"
              :search="searchQuery"
              @click:row="toDetail"
            ></v-data-table>

            <v-btn
              color="primary"
              rounded
              :disabled="isAllLoaded"
              class="ml-4 white--text text-none"
              @click="loadMore"
            >
              Load More Data
            </v-btn>

            <v-btn
              color="primary"
              rounded
              :disabled="isAllLoaded"
              class="ml-4 white--text text-none"
              @click="loadAll"
            >
              Load All Data
            </v-btn>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      tab: null,
      loading: false,
      isAllLoaded: false,
      headers: [
        { text: "Nama", value: "nama_pengguna" },
        { text: "Alamat", value: "alamat" },
        {
          text: "Agama",
          align: "start",
          sortable: true,
          value: "id_agama",
        },

        { text: "Pekerjaan", value: "id_pekerjaan" },
        { text: "Status Perkawinan", value: "id_status_perkawinan" },
        { text: "Tanggal Lahir", value: "tgl_lahir" },
        { text: "Jenis Kelamin", value: "id_jenis_kelamin" },
        { text: "Tanggal Registrasi", value: "tgl_registrasi" },
      ],
      lenders: [],
      lendersPagination: {
        firstPage: 1,
        lastPage: 100,
      },
      borrowers: [],
      borrowersPagination: {
        firstPage: 1,
        lastPage: 100,
      },
      monthlyLenderId: "",
      monthMenu: false,
      monthYearData: "",
    };
  },

  methods: {
    toDetail(item) {
      if (this.tab === 0)
        this.$router.push("/edit-user/lender/" + item.id_lender_db);
      else this.$router.push("/edit-user/borrower/" + item.id_borrower_db);
    },

    async getLender() {
      this.loading = true;
      await store
        .dispatch("office/fetchListLender", this.lendersPagination)
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.lenders = store.state.office.listLender;
      this.lenders.forEach((a) => {
        a.id_pekerjaan = converters.jobType(a.id_pekerjaan);
        a.id_agama = converters.religion(a.id_agama);
        a.tgl_lahir = converters.date(a.tgl_lahir);
        a.tgl_registrasi = converters.date(a.tgl_registrasi);
        a.id_status_perkawinan = converters.maritalStatus(
          a.id_status_perkawinan
        );
        a.id_jenis_kelamin = converters.gender(a.id_jenis_kelamin);
      });
    },

    async getBorrower() {
      this.loading = true;
      await store
        .dispatch("office/fetchListBorrower", this.borrowersPagination)
        .then((r) => {
          this.loading = false;
        });
      this.borrowers = store.state.office.listBorrower;
      this.borrowers.forEach((a) => {
        a.id_pekerjaan = converters.jobType(a.id_pekerjaan);
        a.id_agama = converters.religion(a.id_agama);
        a.tgl_lahir = converters.date(a.tgl_lahir);
        a.tgl_registrasi = converters.date(a.tgl_registrasi);
        a.id_status_perkawinan = converters.maritalStatus(
          a.id_status_perkawinan
        );
        a.id_jenis_kelamin = converters.gender(a.id_jenis_kelamin);
      });
    },

    toMonthly() {
      const month = this.monthYearData.split("-")[1];
      const year = this.monthYearData.split("-")[0];
      this.$router.push(
        "/monthly-lender/" + this.monthlyLenderId + "/" + month + "/" + year
      );
    },

    loadMore() {
      if (this.borrowersPagination.lastPage <= 20000) {
        this.borrowersPagination.lastPage += 1000;
        this.getBorrower();
      } else {
        this.isAllLoaded = true;
      }
    },
    loadAll() {
      if (this.borrowersPagination.lastPage <= 20000) {
        this.borrowersPagination.lastPage += 20000;
        this.getBorrower();
        this.isAllLoaded = true;
      }
    },
  },

  mounted() {
    this.getLender();
    this.getBorrower();
  },
};
</script>
